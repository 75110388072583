module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jack Nevitt","short_name":"Jack Nevitt","start_url":"/","background_color":"#f2f2f2","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/profile_square.JPG"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-168069441-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
